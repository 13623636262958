<template>
  <!-- <el-dropdown trigger="click" @command="handleCommand">
    <div class="btn-dropdown">
      <span>{{title}}</span>
      <div class="icon-down">
        <img src="@/assets/images/arrow-down.png">
      </div>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="(item, index) in data" :key="index" :command="index" @click="handleChange(index)">{{item.text}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown> -->
  <el-select :value="value" placeholder="请选择" @change="handleChange">
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.text"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
export default {
  data() {
    return {
      title: '请选择'
    }
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    data: {
      type: Array,
      default: () => [] // {value:值， text:显示的文本}
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if(this.data.length <= 0) return
        const target = this.data.find(item => item.value == newVal)
        this.title = target.text
      },
      immediate: true
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
      const target = this.data.find(item => item.value == val)
      this.$emit('input', val)
      this.$emit('change', {
        detail: {...target}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-dropdown {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #505E5C;
  height: 32px;
  padding-left: 16px;
  background-color: #F2F3F5;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  .icon-down {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: var(--theme-color);
    margin-left: 16px;
    img {
      width: 14px;
      height: 14px;
    }
  }
}
</style>